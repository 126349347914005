import {
  useState,
  useReducer,
  useEffect,
  KeyboardEvent,
  MutableRefObject,
  MouseEvent,
  SyntheticEvent,
  useMemo,
  useRef,
  useCallback
} from "react";
import { ComponentState, InteractiveReport, InteractiveReportConfiguration } from "types/Component";
import isEqual from "lodash-es/isEqual";

import {
  GroupByFunctionParameter,
  AggregateProperties,
  FilterProperties,
  ComputedColumnProperties,
  SortProperties,
  SaveProperties
} from "types/InteractiveReport";
import { HighlightProperties } from "composants/datatable/dialog/HighlightDialog";
import {
  getInteractiveReportDefinition,
  saveCustomInteractiveReport,
  deleteCustomInteractiveReport,
  downloadInteractiveReportExport,
  createInteractiveReportStateAdmin,
  updateInteractiveReportStateAdmin,
  deleteInteractiveReportStateAdmin
} from "api/interactiveReport";
import { ActionTypeData } from "reducers/Action";
import { Action } from "redux";
import produce from "immer";
import { Pojo, LoadingPojo } from "types/Galaxy";
import { listAsMapOfIndex, checkEntityValid } from "utils/entities.utils";
import {
  mapToRSQLWithOR,
  initRsqlCriteria,
  mapToRSQL,
  getRsqlOperator,
  convertTermByOperator,
  chooseOperatorStrByValue
} from "utils/query.utils";
import { RSQLCriteria, RSQLFilterExpression } from "rsql-criteria-typescript";
import { IndexRange } from "react-virtualized";
import { findViewComplex } from "api";
import Table, { DatatableSort } from "composants/datatable/Table";
import { arrayMove } from "react-sortable-hoc";
import useIsMounted from "hooks/useIsMounted";
import { getUserLang } from "utils/network.utils";
import { getDatePattern, dateMatchPattern, parseDateFromPattern } from "utils/i18n";
import Axios, { CancelTokenSource } from "axios";
import { track } from "tracking";
import useInteractions from "hooks/useInteractions";

type InteractiveReportStateMachine = "INITIAL" | "DEFITION_OK" | "ERROR" | "REFETCH" | "IDLE";

export interface InteractiveReportState {
  state: InteractiveReportStateMachine;
  code: string;
  tableName: string;
  columns: ComponentState[];
  focus: InteractiveReportConfiguration[];
  activeFocus: number;
  search: {
    term: string;
    targetColumn: "ALL" | string;
    filters: FilterProperties[];
  };
  sortBy: SortProperties[];
  breakRows: string[];
  groupBy: {
    columns: string[];
    functionList: GroupByFunctionParameter[];
  };
  computedColumns: ComputedColumnProperties[];
  aggregates: AggregateProperties[];
  highlights: HighlightProperties[];
  entities: Record<string, Pojo | LoadingPojo>;
  totalRecords: number;
  selectedRows: number[];
}

export type InteractiveReportActions =
  // reset
  | ActionTypeData<"ADMIN_RESET_AFTER_COL_CHANGES", InteractiveReport>
  // definition
  | ActionTypeData<"SET_DEFINITION", InteractiveReport>
  | ActionTypeData<"CHANGE_FOCUS", number>
  // state
  | ActionTypeData<"STATE_CHANGE", InteractiveReportStateMachine>
  // search
  | ActionTypeData<"CHANGE_SEARCH_TERM", string>
  | ActionTypeData<"CHANGE_SEARCH_TARGET", "ALL" | string>
  | ActionTypeData<"CHANGE_SEARCH_FILTER", FilterProperties[]>
  // break row
  | ActionTypeData<"CHANGE_BREAK_ROW_COLUMN", string[]>
  // group by
  | ActionTypeData<
      "CHANGE_GROUP_BY",
      { columns: string[]; functionList: GroupByFunctionParameter[] }
    >
  // computed column
  | ActionTypeData<"CHANGE_OR_ADD_COMPUTED_COLUMN", ComputedColumnProperties>
  | ActionTypeData<"DELETE_INDEX_COMPUTED_COLUMN", number>
  | Action<"DELETE_ALL_COMPUTED_COLUMNS">
  // aggregates
  | ActionTypeData<"CHANGE_OR_ADD_AGGREGATES", AggregateProperties>
  | ActionTypeData<"DELETE_INDEX_AGGREGATES", number>
  | Action<"DELETE_ALL_AGGREGATES">
  // highlights
  | ActionTypeData<"CHANGE_OR_ADD_HIGHLIGHTS", HighlightProperties>
  | ActionTypeData<"DELETE_INDEX_HIGHLIGHTS", number>
  | Action<"DELETE_ALL_HIGHLIGHTS">
  // sort by
  | ActionTypeData<"SET_SORT_BY", SortProperties[]>
  // columns
  | Action<"HIDE_ALL_COLUMNS">
  | Action<"SHOW_ALL_COLUMNS">
  | ActionTypeData<"TOGGLE_VISIBILITY_COLUMNS", number>
  | ActionTypeData<"REORDER_COLUMNS", ComponentState[]>
  | ActionTypeData<"RESIZE_COLUMN", { columnName: string; deltaX: number; datatableWidth: number }>
  // data
  | ActionTypeData<
      "ADD_ENTITIES",
      {
        start: number;
        size: number;
        entities: Pojo[];
        totalRecords: number;
        reset: boolean;
      }
    >
  | ActionTypeData<"LOADING_ENTITIES", { start: number; size: number }>
  | Action<"RESET_ENTITIES">
  | ActionTypeData<"CHANGE_ENTITIES_FIELD", { index: number; name: string; value: any }>
  | ActionTypeData<"SELECT_ROW", number | "ALL">
  | ActionTypeData<"UNSELECT_ROW", number | "ALL">;

const initialState: InteractiveReportState = {
  state: "INITIAL",
  code: "",
  tableName: "",
  columns: [],
  focus: [],
  activeFocus: -1,
  search: {
    term: "",
    targetColumn: "ALL",
    filters: []
  },
  sortBy: [],
  breakRows: [],
  groupBy: {
    columns: [],
    functionList: []
  },
  computedColumns: [],
  aggregates: [],
  highlights: [],
  entities: {},
  totalRecords: 0,
  selectedRows: []
};

function reducerInteractiveReport(
  state: InteractiveReportState = initialState,
  action: InteractiveReportActions
): InteractiveReportState {
  switch (action.type) {
    case "ADMIN_RESET_AFTER_COL_CHANGES": {
      return initializeDefinition(initialState, action.payload);
    }
    case "SET_DEFINITION":
      const definition = action.payload;
      return initializeDefinition(state, definition);
    case "CHANGE_FOCUS":
      const newConf = state.focus[action.payload];
      return changeFocus(state, newConf, action.payload);

    case "STATE_CHANGE":
      return produce(state, draft => {
        draft.state = action.payload;
      });

    case "SHOW_ALL_COLUMNS":
      return produce(state, draft => {
        for (let col of draft.columns) {
          col.compoVisible = true;
        }
      });
    case "HIDE_ALL_COLUMNS":
      return produce(state, draft => {
        for (let col of draft.columns) {
          col.compoVisible = false;
        }
      });

    case "REORDER_COLUMNS":
      return { ...state, columns: action.payload };

    case "RESIZE_COLUMN":
      const indexCol = state.columns.findIndex(
        column => column.column === action.payload.columnName
      );
      const percentDelta = action.payload.deltaX / action.payload.datatableWidth;

      return produce(state, draft => {
        const currentFocus = draft.focus[state.activeFocus];
        if (currentFocus) {
          if (!currentFocus.includesSize) {
            currentFocus.includesSize = {};
          }

          const prevWidth =
            currentFocus.includesSize[action.payload.columnName] ||
            draft.columns[indexCol].contentSize;

          const widthAdd = percentDelta * action.payload.datatableWidth;
          let newWidth = prevWidth + widthAdd;
          if (newWidth < 50) newWidth = 50;

          if (!isNaN(newWidth)) {
            currentFocus.includesSize[action.payload.columnName] = newWidth;
          }
        } else {
          const prevWidth = state.columns[indexCol].contentSize;
          const widthAdd = percentDelta * action.payload.datatableWidth;
          const newWidth = prevWidth + widthAdd;

          if (!isNaN(newWidth)) {
            draft.columns[indexCol].contentSize = newWidth;
            draft.columns[indexCol].labelSize = newWidth;
          }
        }
      });

    case "TOGGLE_VISIBILITY_COLUMNS":
      if (action.payload === -1) {
        return state;
      }

      let numberOfVisibleColumn = state.columns.filter(col => col.compoVisible === true).length;

      let newState = produce(state, draft => {
        draft.columns[action.payload].compoVisible = !state.columns[action.payload].compoVisible;
      });

      return {
        ...newState,
        columns: arrayMove(newState.columns, action.payload, numberOfVisibleColumn)
      };

    case "CHANGE_SEARCH_TERM":
      return produce(state, draft => {
        draft.search.term = action.payload;
      });

    case "CHANGE_SEARCH_TARGET":
      return produce(state, draft => {
        draft.search.targetColumn = action.payload;
      });

    case "CHANGE_SEARCH_FILTER":
      return produce(state, draft => {
        draft.state = "REFETCH";
        draft.search.filters = action.payload;
      });

    case "CHANGE_BREAK_ROW_COLUMN":
      return produce(state, draft => {
        draft.state = "REFETCH";
        draft.breakRows = action.payload;
      });

    case "CHANGE_GROUP_BY":
      const shouldRemoveGroupByFunctionFromOrderBy =
        state.groupBy.functionList.length > 0 && action.payload.functionList.length === 0;
      return produce(state, draft => {
        draft.state = "REFETCH";

        if (shouldRemoveGroupByFunctionFromOrderBy) {
          draft.sortBy = draft.sortBy.filter(
            sort => state.groupBy.functionList.findIndex(fn => fn.label === sort.column) === -1
          );
        }
        draft.groupBy.columns = action.payload.columns;
        draft.groupBy.functionList = action.payload.functionList.filter(
          fn => fn.column && fn.functionType
        );
      });

    case "CHANGE_OR_ADD_COMPUTED_COLUMN":
      return produce(state, draft => {
        draft.state = "REFETCH";
        const index = draft.computedColumns.findIndex(el => el.id === action.payload.id);
        if (index !== -1) {
          draft.computedColumns[index] = action.payload;
        } else {
          draft.computedColumns.push(action.payload);
        }
      });

    case "DELETE_INDEX_COMPUTED_COLUMN":
      return produce(state, draft => {
        draft.state = "REFETCH";
        draft.computedColumns.splice(action.payload, 1);
      });

    case "DELETE_ALL_COMPUTED_COLUMNS":
      return { ...state, state: "REFETCH", computedColumns: [] };

    case "CHANGE_OR_ADD_AGGREGATES":
      return produce(state, draft => {
        draft.state = "REFETCH";
        const index = draft.aggregates.findIndex(el => el.id === action.payload.id);
        if (index !== -1) {
          draft.aggregates[index] = action.payload;
        } else {
          draft.aggregates.push(action.payload);
        }
      });

    case "DELETE_INDEX_AGGREGATES":
      return produce(state, draft => {
        draft.state = "REFETCH";
        draft.aggregates.splice(action.payload, 1);
      });

    case "DELETE_ALL_AGGREGATES":
      return { ...state, state: "REFETCH", aggregates: [] };

    case "CHANGE_OR_ADD_HIGHLIGHTS":
      return produce(state, draft => {
        const index = draft.highlights.findIndex(el => el.id === action.payload.id);
        if (index !== -1) {
          draft.highlights[index] = action.payload;
        } else {
          draft.highlights.push(action.payload);
        }
        draft.highlights.sort((a, b) => b.sequence - a.sequence);
      });

    case "DELETE_INDEX_HIGHLIGHTS":
      return produce(state, draft => {
        draft.highlights.splice(action.payload, 1);
      });

    case "DELETE_ALL_HIGHLIGHTS":
      return { ...state, highlights: [] };

    case "SET_SORT_BY":
      return { ...state, state: "REFETCH", sortBy: action.payload };

    case "ADD_ENTITIES":
      if (action.payload.reset) {
        return {
          ...state,
          state: "IDLE",
          entities: listAsMapOfIndex(action.payload.entities, action.payload.start),
          totalRecords: action.payload.totalRecords,
          selectedRows: []
        };
      } else {
        return {
          ...state,
          state: "IDLE",
          entities: {
            ...state.entities,
            ...listAsMapOfIndex(action.payload.entities, action.payload.start)
          },
          totalRecords: action.payload.totalRecords
        };
      }

    case "LOADING_ENTITIES":
      return produce(state, draft => {
        for (
          let i = action.payload.start, max = action.payload.start + action.payload.size;
          i < max;
          i++
        ) {
          draft.entities[i] = "LOADING_POJO";
        }
      });

    case "RESET_ENTITIES":
      return { ...state, state: "IDLE", entities: {}, totalRecords: 0, selectedRows: [] };

    case "CHANGE_ENTITIES_FIELD": {
      const { index, name, value } = action.payload;
      const pojo = state.entities[index];
      if (pojo !== "LOADING_POJO") {
        return {
          ...state,
          entities: {
            ...state.entities,
            [index]: {
              ...pojo,
              [name]: value
            }
          }
        };
      } else {
        return state;
      }
    }

    case "SELECT_ROW":
      if (action.payload === "ALL") {
        // on sélectionne tous les ids
        let newSelectedRows: number[] = [];
        for (let i = 0; i < state.totalRecords; i++) {
          const entity = state.entities[i];
          if (checkEntityValid(entity)) newSelectedRows.push(i);
        }

        return {
          ...state,
          selectedRows: newSelectedRows
        };
      } else if (!state.selectedRows.includes(action.payload)) {
        let newSelectedRows = state.selectedRows.concat(action.payload);
        return {
          ...state,
          selectedRows: newSelectedRows
        };
      } else {
        return state;
      }

    case "UNSELECT_ROW":
      if (action.payload === "ALL") {
        return {
          ...state,
          selectedRows: []
        };
      } else {
        const newSelectedRows = state.selectedRows.filter(
          row => action.payload !== "ALL" && row !== action.payload
        );

        return {
          ...state,
          selectedRows: newSelectedRows
        };
      }

    default:
      return state;
  }
}

function getColumnOrdered(
  columnsOrigin: ComponentState[],
  currentConfiguration: InteractiveReportConfiguration
) {
  let columns: ComponentState[] = columnsOrigin;
  if (currentConfiguration.includes) {
    columns = [];
    let indexed: number[] = [];
    for (let include of currentConfiguration.includes) {
      const colIndex = columnsOrigin.findIndex(col => col.column === include);

      if (colIndex !== -1) {
        columns.push({ ...columnsOrigin[colIndex], compoVisible: true });
        indexed.push(colIndex);
      }
    }

    for (let i = 0, size = columnsOrigin.length; i < size; i++) {
      if (indexed.indexOf(i) === -1) {
        columns.push({ ...columnsOrigin[i], compoVisible: false });
      }
    }
  }

  return columns;
}

function initializeDefinition(
  state: InteractiveReportState,
  definition: InteractiveReport,
  activeFocus: number = Math.max(0, 0) // 0 et non definition.configurations.length - 1 pour load le premier
): InteractiveReportState {
  if (definition.configurations.length > 0) {
    let configuration = definition.configurations;
    configuration.sort((a, b) => {
      if (a.typeFocus === "user" && b.typeFocus !== "user") {
        return -1;
      }
      if (a.typeFocus !== "user" && b.typeFocus === "user") {
        return 1;
      }
      return (
        (a.position === null ? 9999 : Number(a.position)) -
        (b.position === null ? 9999 : Number(b.position))
      );
    });

    const currentConfiguration = definition.configurations[activeFocus];
    let columns: ComponentState[] = getColumnOrdered(definition.columns, currentConfiguration);

    return {
      state: "DEFITION_OK",
      code: definition.code,
      tableName: definition.tableName,
      columns,
      focus: configuration,
      activeFocus,
      search: {
        term: "",
        targetColumn: "ALL",
        filters: currentConfiguration.filters
      },
      groupBy: {
        columns: currentConfiguration.groupBy.groupBy,
        functionList: currentConfiguration.groupBy.functions
      },
      aggregates: currentConfiguration.aggregates,
      breakRows: currentConfiguration.breakRows,
      computedColumns: currentConfiguration.computedColumns,
      highlights: currentConfiguration.highlights,
      sortBy: currentConfiguration.sortBy,
      entities: {},
      totalRecords: 0,
      selectedRows: []
    };
  } else {
    return {
      ...state,
      state: "DEFITION_OK",
      code: definition.code,
      tableName: definition.tableName,
      columns: definition.columns,
      focus: []
    };
  }
}

function changeFocus(
  state: InteractiveReportState,
  conf: InteractiveReportConfiguration,
  activeFocus: number
): InteractiveReportState {
  let columns: ComponentState[] = getColumnOrdered(state.columns, conf);
  return {
    ...state,
    state: "REFETCH",
    columns,
    activeFocus,
    search: {
      term: "",
      targetColumn: "ALL",
      filters: conf.filters
    },
    groupBy: {
      columns: conf.groupBy.groupBy,
      functionList: conf.groupBy.functions
    },
    aggregates: conf.aggregates,
    breakRows: conf.breakRows,
    computedColumns: conf.computedColumns,
    highlights: conf.highlights,
    sortBy: conf.sortBy
  };
}

export function searchTerm(
  columns: ComponentState[],
  targetColumn: string,
  term: string,
  existingFilters: FilterProperties[]
) {
  const userDatePattern = getDatePattern(getUserLang());
  const searchObj: Record<string, string> = {};

  if (term) {
    if (targetColumn === "ALL") {
      for (let col of columns) {
        let isTermValid = false;
        let replacedTerm: any = null;
        if (col.typeCompo === "NUMBER" || (col.typeCompo === "LINK" && col.isNumber)) {
          const int = Number.parseInt(term, 10);
          const float = Number.parseFloat(term);

          if (
            (!isNaN(int) && int.toString() === term) ||
            (!isNaN(float) && float.toString() === term)
          ) {
            isTermValid = true;
          }
        } else if (col.typeCompo === "DATE" && dateMatchPattern(term, userDatePattern)) {
          isTermValid = true;
          replacedTerm = parseDateFromPattern(term, userDatePattern);
        } else if (col.typeCompo === "TEXT" || col.typeCompo === "LINK") {
          isTermValid = true;
        }

        if (isTermValid) {
          searchObj[col.column] = replacedTerm || term;
        }
      }
    } else {
      const col = columns.find(c => c.column === targetColumn);
      if (!col) {
        return undefined;
      }
      let isTermValid = false;
      let replacedTerm: any = null;
      if (col.typeCompo === "NUMBER" || (col.typeCompo === "LINK" && col.isNumber)) {
        const int = Number.parseInt(term, 10);
        const float = Number.parseFloat(term);

        if (
          (!isNaN(int) && int.toString() === term) ||
          (!isNaN(float) && float.toString() === term)
        ) {
          isTermValid = true;
        }
      } else if (col.typeCompo === "DATE" && dateMatchPattern(term, userDatePattern)) {
        isTermValid = true;
        replacedTerm = parseDateFromPattern(term, userDatePattern);
      } else if (col.typeCompo === "TEXT" || col.typeCompo === "LINK") {
        isTermValid = true;
      }

      if (isTermValid) {
        searchObj[col.column] = replacedTerm || term;
      }
    }
  }

  if (Object.keys(searchObj).length > 0 || existingFilters.length > 0) {
    const andCondition = initRsqlCriteria();

    for (let filter of existingFilters) {
      const col = columns.find(col => col.column === filter.column);
      if (col) {
        const value = convertTermByOperator(filter.value, filter.operator);
        const expression = new RSQLFilterExpression(
          filter.column,
          getRsqlOperator(filter.operator),
          filter.operator === "OPER_NULL" || filter.operator === "OPER_NOT_NULL"
            ? "null"
            : typeof value === "string"
            ? value.replace(
                /%/g,
                ["OPER_LIKE_ANYWHERE", "OPER_LIKE_END", "OPER_LIKE_START"].includes(filter.operator)
                  ? "*"
                  : ""
              )
            : value
        );
        andCondition.filters.and(expression);
      }
    }

    const orCondition = mapToRSQLWithOR(searchObj);

    andCondition.and(orCondition);
    return andCondition;
  } else {
    return undefined;
  }
}

// const interactions = selectContextAsDetail(state.interactions, {
//   sjmoCode: ownProps.sjmoCode,
//   ctrlKey: `interactiveReport:${ownProps.definitionId}`
// });

const IR_CACHE = new Map<string, InteractiveReportState>();

function getCacheKey(sjmoCode: string, definitionId: string) {
  return sjmoCode + ":::interactiveReport:::" + definitionId;
}

export const getInteractiveReportCacheKey = getCacheKey;

export function deleteInteractiveReportCache(key: string) {
  IR_CACHE.delete(key);
}

export function deleteInteractiveReportCacheByModule(code: string) {
  const keys = IR_CACHE.keys();

  for (let key of keys) {
    if (key.startsWith(code + ":::interactiveReport:::")) {
      IR_CACHE.delete(key);
    }
  }
}

export const IR_MINIMUM_BATCH_SIZE = 200;

export function useInteractiveReports(
  sjmoCode: string,
  definitionId: string,
  inputSearchRef: MutableRefObject<HTMLInputElement | null> = { current: null },
  tableRef: MutableRefObject<Table | null> = { current: null }
) {
  const IR_KEY = getCacheKey(sjmoCode, definitionId);

  const interactions = useInteractions({
    sjmoCode: sjmoCode,
    ctrlKey: `interactiveReport:${definitionId}`
  });

  const cacheState = IR_CACHE.get(IR_KEY);

  const [interactiveReport, dispatch] = useReducer(
    reducerInteractiveReport,
    cacheState ?? initialState
  );

  useEffect(() => {
    IR_CACHE.set(IR_KEY, interactiveReport);
  }, [IR_KEY, interactiveReport]);

  const [highlightDelete, setHighlightDelete] = useState<boolean>(false);

  const dialogBreakRows = useIsOpen("break");
  const dialogGroupBy = useIsOpen("group-by");
  const dialogComputedColumn = useIsOpen("computed-columns");
  const dialogAggregate = useIsOpen("aggregate");
  const dialogHighlights = useIsOpen("highlights");
  const dialogSave = useIsOpen("save");
  const dialogAdminSave = useIsOpen("admin-save");
  const dialogAdminCreate = useIsOpen("admin-create");
  const dialogFilter = useIsOpen("filter");

  const isMounted = useIsMounted();

  useEffect(() => {
    if (interactiveReport.state === "INITIAL") {
      getInteractiveReportDefinition(definitionId).then(res => {
        dispatch({ type: "SET_DEFINITION", payload: res.data });
      });
    }
  }, [definitionId, interactiveReport.state]);

  const fetchDataInternal = useCallback(
    async function fetchDataInternal(
      {
        startIndex = 0,
        stopIndex = IR_MINIMUM_BATCH_SIZE,
        breakRows = interactiveReport.breakRows,
        groupBy = interactiveReport.groupBy,
        aggregates = interactiveReport.aggregates,
        rsqlFilter = searchTerm(
          interactiveReport.columns,
          interactiveReport.search.targetColumn,
          interactiveReport.search.term,
          interactiveReport.search.filters
        ),
        sortBy = interactiveReport.sortBy
      }: {
        startIndex?: number;
        stopIndex?: number;
        breakRows?: string[];
        groupBy?: {
          columns: string[];
          functionList: GroupByFunctionParameter[];
        };
        aggregates?: AggregateProperties[];
        rsqlFilter?: RSQLCriteria;
        sortBy?: SortProperties[];
      } = {},
      source?: CancelTokenSource
    ) {
      let rsql = rsqlFilter;
      if (Object.keys(interactions).length > 0) {
        const rsqlInteractions = mapToRSQL(interactions);
        if (rsqlFilter) {
          rsqlInteractions.and(rsqlFilter);
        }
        rsql = rsqlInteractions;
      }

      try {
        const res = await fetchData(
          {
            tableName: interactiveReport.tableName,
            rsqlFilter: rsql,
            columns: interactiveReport.columns.map(col => col.column),
            sorts: sortBy,
            breakRows: breakRows,
            groupBy,
            aggregates,
            startIndex,
            stopIndex,
            reset: true
          },
          source
        );
        if (!isMounted) return;

        dispatch({ type: "ADD_ENTITIES", payload: res });
      } catch (e) {
        if (!isMounted || Axios.isCancel(e)) return;
        dispatch({ type: "RESET_ENTITIES" });
        console.error("erreur lors du reset des données", e);
      }

      // si on a un reset, le scroll de la personne n'a plus de sens
      // on remonte alors en haut de la table.
      tableRef.current && tableRef.current.scrollTop();
    },
    [
      interactions,
      interactiveReport.aggregates,
      interactiveReport.breakRows,
      interactiveReport.columns,
      interactiveReport.groupBy,
      interactiveReport.search.filters,
      interactiveReport.search.targetColumn,
      interactiveReport.search.term,
      interactiveReport.sortBy,
      interactiveReport.tableName,
      isMounted,
      tableRef
    ]
  );

  useEffect(() => {
    if (interactiveReport.state === "DEFITION_OK" || interactiveReport.state === "REFETCH") {
      fetchDataInternal().then(() => {
        if (interactiveReport.state === "REFETCH") {
          tableRef.current && tableRef.current.recomputeGrids();
        }
      });
    }
  }, [fetchDataInternal, interactiveReport.state, tableRef]);

  // il faut que l'on traque si les interactions change dans le lifecycle de notre composant
  // pour cela, on utilise une ref pour enregistrer l'interaction - 1 lors de l'execution de la fonction.
  //
  // le useEffect sans aucune dépendance veut dire que chaque update de notre composant va appeler le useEffect.
  // donc, à chaque fois que le composant est update : via le parent ou via un changement de son state interne
  // on vérifie si l'interaction à changé par rapport au dernier enregistrement dans notre ref.
  // Si c'est le cas, on reset les data du composant via le fetchDataInternal
  // on triche avec le fetchDataInternal, on veut avoir la dernière référence mais on ne veut pas
  // avoir un lancement de useEffect lors du changement de cette fonction. On passe par la ref du coup
  const fetchDataInternalRef = useRef(fetchDataInternal);
  // toujours la dernière version à jour
  fetchDataInternalRef.current = fetchDataInternal;
  useEffect(() => {
    const source = Axios.CancelToken.source();
    const fetch = fetchDataInternalRef.current;
    fetch(undefined, source);
    // si l'interaction change,
    return () => {
      source.cancel();
    };
  }, [interactions]);

  const customColumnsSize: Record<string, number> = useMemo(() => {
    if (interactiveReport.activeFocus === -1) {
      return {};
    }
    if (interactiveReport.focus.length > 0) {
      const conf = interactiveReport.focus[interactiveReport.activeFocus];
      return conf.includesSize || {};
    } else {
      return {};
    }
  }, [interactiveReport.activeFocus, interactiveReport.focus]);

  // COLUMNS PART -----------------------------------------------------------------------------------------------------

  function toggleVisibility(index: number) {
    dispatch({ type: "TOGGLE_VISIBILITY_COLUMNS", payload: index });
  }

  function hideAllColumns() {
    dispatch({ type: "HIDE_ALL_COLUMNS" });
  }

  function showAllColumns() {
    dispatch({ type: "SHOW_ALL_COLUMNS" });
  }

  function onReorderColumn({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) {
    // on enlève 1 à chaque index car la première colonne de notre interactive-report est
    // une checkbox qui n'est pas listé dans la liste des colonnes du composant.
    dispatch({
      type: "REORDER_COLUMNS",
      payload: arrayMove(interactiveReport.columns, oldIndex - 1, newIndex - 1)
    });

    tableRef.current && tableRef.current.recomputeGrids();
    track("ir::column::reorder");
  }

  function onResizeColumn(columnName: string, deltaX: number, datatableWidth: number) {
    dispatch({ type: "RESIZE_COLUMN", payload: { columnName, deltaX, datatableWidth } });

    tableRef.current && tableRef.current.recomputeGrids();
    track("ir::column::resize");
  }

  // SEARCH PART ------------------------------------------------------------------------------------------------------

  function changeTargetColumn(e: MouseEvent<HTMLAnchorElement>) {
    // si jamais on clique de nouveau sur la colonne déjà filtré, c'est que l'on souhaite décocher la recherche
    if (e.currentTarget.dataset.value === interactiveReport.search.targetColumn) {
      dispatch({ type: "CHANGE_SEARCH_TARGET", payload: "ALL" });
      track("ir::search::target", { target: "ALL" });
    } else {
      // si on a pas de valeur, on passe reset "ALL"
      dispatch({ type: "CHANGE_SEARCH_TARGET", payload: e.currentTarget.dataset.value || "ALL" });
      track("ir::search::target", { target: e.currentTarget.dataset.value || "ALL" });
    }
    // si on choisi une colonne pour la recherche, c'est que le souhaite recherche,
    // on fait un focus sur le bouton de recherche
    inputSearchRef.current && inputSearchRef.current.focus();
  }

  function onKeyDownSearchTerm(e: KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      dispatch({ type: "STATE_CHANGE", payload: "REFETCH" });
      track("ir::search::validate::shortcut");
    }
  }

  function onChangeSearchTerm(e: SyntheticEvent<HTMLInputElement>) {
    dispatch({ type: "CHANGE_SEARCH_TERM", payload: e.currentTarget.value });
  }

  function executeSearch() {
    dispatch({ type: "STATE_CHANGE", payload: "REFETCH" });
    track("ir::search::validate::click");
  }

  function onSelectFilter(column: string, value: any) {
    let newFilters: FilterProperties[];
    if (interactiveReport.search.filters.findIndex(f => f.column === column) === -1) {
      const operator = chooseOperatorStrByValue(value);
      // les filtres devant des suggestions utilisent OPER_EQ
      newFilters = [
        ...interactiveReport.search.filters,
        { type: "normal", column, operator, value }
      ];
    } else {
      newFilters = interactiveReport.search.filters.filter(
        f => f.column !== column && f.type === "normal"
      );
    }
    dispatch({ type: "CHANGE_SEARCH_FILTER", payload: newFilters });
    tableRef.current && tableRef.current.recomputeGrids();
  }

  function clearFilters() {
    dispatch({
      type: "CHANGE_SEARCH_FILTER",
      payload: interactiveReport.search.filters.filter(it => it.type === "static")
    });
    tableRef.current && tableRef.current.recomputeGrids();
    track("ir::filter::clear");
  }

  function setFilters(filters: FilterProperties[]) {
    dispatch({ type: "CHANGE_SEARCH_FILTER", payload: filters });
    tableRef.current && tableRef.current.recomputeGrids();
    track("ir::filter::validate");
  }

  // BREAK ROWS -------------------------------------------------------------------------------------------------------

  function validateBreakRows(cols: string[]) {
    dispatch({ type: "CHANGE_BREAK_ROW_COLUMN", payload: cols });
    dialogBreakRows.setIsOpen(false);

    tableRef.current && tableRef.current.recomputeGrids();
    if (cols.length > 0) {
      track("ir::break::validate");
    } else {
      track("ir::break::clear");
    }
  }

  // GROUP BY ---------------------------------------------------------------------------------------------------------

  function validateGroupBy(columns: string[], functionList: GroupByFunctionParameter[]) {
    dispatch({ type: "CHANGE_GROUP_BY", payload: { columns, functionList } });
    dialogGroupBy.setIsOpen(false);
    track("ir::break::validate");
  }

  // COMPUTED COLUMNS -------------------------------------------------------------------------------------------------

  function validateComputedColumn(calcProp: ComputedColumnProperties) {
    dispatch({ type: "CHANGE_OR_ADD_COMPUTED_COLUMN", payload: calcProp });
    dialogComputedColumn.setIsOpen(false);
    track("ir::computed-columns::validate");
  }

  function deleteComputedColumn(index: number) {
    dispatch({ type: "DELETE_INDEX_COMPUTED_COLUMN", payload: index });
  }

  function deleteAllComputedColumn() {
    dispatch({ type: "DELETE_ALL_COMPUTED_COLUMNS" });
    track("ir::computed-columns::clear");
  }

  // AGGREGATES -------------------------------------------------------------------------------------------------------

  function validateAggregate(aggr: AggregateProperties) {
    dispatch({ type: "CHANGE_OR_ADD_AGGREGATES", payload: aggr });
    dialogAggregate.toggle();
    tableRef.current && tableRef.current.recomputeBody();
    track("ir::aggregate::validate");
  }

  function deleteAggregate(index: number) {
    dispatch({ type: "DELETE_INDEX_AGGREGATES", payload: index });
  }

  function deleteAllAggregate() {
    dispatch({ type: "DELETE_ALL_AGGREGATES" });
    track("ir::aggregate::clear");
  }

  // HIGHLIGHTS -------------------------------------------------------------------------------------------------------

  function validateHighlights(highlight: HighlightProperties) {
    dispatch({ type: "CHANGE_OR_ADD_HIGHLIGHTS", payload: highlight });
    dialogHighlights.toggle();
    tableRef.current && tableRef.current.recomputeGrids();
    track("ir::highlights::validate");
  }

  function deleteHighlights(index: number) {
    dispatch({ type: "DELETE_INDEX_HIGHLIGHTS", payload: index });
  }

  function deleteAllHighlights() {
    dispatch({ type: "DELETE_ALL_HIGHLIGHTS" });
    track("ir::highlights::clear");
  }

  // SORT -------------------------------------------------------------------------------------------------------------

  function toggleSort(sortsBy: SortProperties[], sort: DatatableSort, column: string) {
    let newSorts: SortProperties[];
    if (sortsBy.findIndex(s => s.column === column && s.sort === sort) !== -1) {
      newSorts = sortsBy.filter(s => s.column !== column);
    } else {
      let workingSortBy = sortsBy.filter(s => s.column !== column);
      newSorts = [...workingSortBy, { column, sort }];
    }

    dispatch({ type: "SET_SORT_BY", payload: newSorts });
    tableRef.current && tableRef.current.recomputeGrids();
    track("ir::sort", { order: sort });
  }

  // ASYNC CALL -------------------------------------------------------------------------------------------------------

  async function loadMoreData({ startIndex, stopIndex }: IndexRange) {
    dispatch({
      type: "LOADING_ENTITIES",
      payload: { start: startIndex, size: stopIndex - startIndex }
    });

    let rsqlFilter = searchTerm(
      interactiveReport.columns,
      interactiveReport.search.targetColumn,
      interactiveReport.search.term,
      interactiveReport.search.filters
    );

    if (interactions) {
      const rsqlInteractions = mapToRSQL(interactions);
      if (rsqlFilter) {
        rsqlInteractions.and(rsqlFilter);
      }
      rsqlFilter = rsqlInteractions;
    }

    try {
      const res = await fetchData({
        reset: false,
        tableName: interactiveReport.tableName,
        rsqlFilter,
        columns: interactiveReport.columns.map(col => col.column),
        breakRows: interactiveReport.breakRows,
        groupBy: interactiveReport.groupBy,
        aggregates: interactiveReport.aggregates,
        sorts: interactiveReport.sortBy,
        startIndex,
        stopIndex
      });
      if (!isMounted) return;

      dispatch({ type: "ADD_ENTITIES", payload: res });
    } catch (e) {
      if (!isMounted || Axios.isCancel(e)) return;
      // est-ce qu'il faut vraiment reset les entités si un loadMore plante ?
      // si on le fait pas, ça permet déjà de bosser avec les entités en cours.
      // Par contre, on a des entités en "LOADING" qui sont plantés,
      // il faudrait peut-être avoir un type "ERROR" pour connaître les entités en erreur
      // de loading ?
      // cela permettrait de pouvoir les refetch en arrière plan s'il y en a.
      dispatch({ type: "RESET_ENTITIES" });
      console.error("erreur lors du chargement lazy des données", e);
    }
  }

  // focus actions ----------------------------------------------------------------------------------------------------

  function changeHighlightDelete(boolDelete: boolean) {
    setHighlightDelete(boolDelete);
  }

  function onFocusChange(index: number) {
    dispatch({ type: "CHANGE_FOCUS", payload: index });
    tableRef.current && tableRef.current.recomputeGrids();
  }

  //Faire la fonction comme onFocusChange
  async function onFocusDelete(definitionId: string, focusId: number | string) {
    try {
      const res = await deleteInteractiveReportStateAdmin(definitionId, focusId);
      if (!isMounted) return;

      dispatch({ type: "SET_DEFINITION", payload: res.data });
    } catch (e) {
      console.error("erreur lors de la sauvegarde", e);
    }
    tableRef.current && tableRef.current.recomputeGrids();
  }

  async function onSave(saveProperties: SaveProperties) {
    const currentFocusId =
      interactiveReport.activeFocus !== -1 &&
      interactiveReport.focus[interactiveReport.activeFocus].id;

    const currentIncludeSize =
      interactiveReport.activeFocus !== -1 &&
      interactiveReport.focus[interactiveReport.activeFocus].includesSize;

    const visibleColumns = interactiveReport.columns.filter(col => col.compoVisible);

    const newConfiguration: InteractiveReportConfiguration = {
      typeFocus: "user",
      id: currentFocusId === saveProperties.id ? currentFocusId : undefined,
      aggregates: interactiveReport.aggregates,
      breakRows: interactiveReport.breakRows,
      computedColumns: interactiveReport.computedColumns,
      filters: interactiveReport.search.filters,
      groupBy: {
        groupBy: interactiveReport.groupBy.columns,
        functions: interactiveReport.groupBy.functionList
      },
      highlights: interactiveReport.highlights,
      sortBy: interactiveReport.sortBy,
      label: saveProperties.label,
      position: null,
      includes: visibleColumns.map(col => col.column),
      includesSize:
        currentIncludeSize ||
        visibleColumns.reduce((acc, curr) => {
          acc[curr.column] = curr.contentSize;
          return acc;
        }, {}),
      isGlobal: saveProperties.isGlobal
    };

    try {
      // on sauvegarde et on refetch la totalité de interactive-report
      const res = await saveCustomInteractiveReport(definitionId, newConfiguration);
      if (!isMounted) return;

      dispatch({ type: "SET_DEFINITION", payload: res.data });

      dialogSave.setIsOpen(false);
    } catch (e) {
      console.error("erreur lors de la sauvegarde", e);
    }
    tableRef.current && tableRef.current.recomputeGrids();
  }

  async function onDelete(configurationId: number | string) {
    try {
      const res = await deleteCustomInteractiveReport(definitionId, configurationId);
      if (!isMounted) return;

      dispatch({ type: "SET_DEFINITION", payload: res.data });
      dialogSave.setIsOpen(false);
    } catch (e) {
      console.error("erreur lors de la suppression", e);
    }

    tableRef.current && tableRef.current.recomputeGrids();
  }

  function getNewConfiguration(saveProperties: SaveProperties, typeFocus?: "user" | "global") {
    const currentFocusId =
      interactiveReport.activeFocus !== -1 &&
      interactiveReport.focus[interactiveReport.activeFocus]?.id;

    const currentIncludeSize =
      interactiveReport.activeFocus !== -1 &&
      interactiveReport.focus[interactiveReport.activeFocus]?.includesSize;

    const visibleColumns = interactiveReport.columns.filter(col => col.compoVisible);
    const newConfiguration: InteractiveReportConfiguration = {
      typeFocus: typeFocus ?? "global",
      id: currentFocusId === saveProperties.id ? currentFocusId : undefined,
      aggregates: interactiveReport.aggregates,
      breakRows: interactiveReport.breakRows,
      computedColumns: interactiveReport.computedColumns,
      filters: interactiveReport.search.filters,
      groupBy: {
        groupBy: interactiveReport.groupBy.columns,
        functions: interactiveReport.groupBy.functionList
      },
      highlights: interactiveReport.highlights,
      sortBy: interactiveReport.sortBy,
      label: saveProperties.label,
      position: saveProperties.position,
      includes: visibleColumns.map(col => col.column),
      includesSize:
        currentIncludeSize ||
        visibleColumns.reduce((acc, curr) => {
          acc[curr.column] = curr.contentSize;
          return acc;
        }, {}),
      isGlobal: true
    };

    return newConfiguration;
  }

  async function onAdminUpdate(saveProperties: SaveProperties, typeFocus?: "user" | "global") {
    const newConfiguration = getNewConfiguration(saveProperties, typeFocus);
    try {
      // on sauvegarde et on refetch la totalité de interactive-report
      const res = await updateInteractiveReportStateAdmin(sjmoCode, definitionId, newConfiguration);

      if (!isMounted) return;

      dispatch({ type: "SET_DEFINITION", payload: res.data });
    } catch (e) {
      console.error("erreur lors de la sauvegarde", e);
    }
    tableRef.current && tableRef.current.recomputeGrids();
  }

  async function onAdminCreate(saveProperties: SaveProperties) {
    const newConfiguration = getNewConfiguration(saveProperties);
    try {
      // on sauvegarde et on refetch la totalité de interactive-report
      const res = await createInteractiveReportStateAdmin(sjmoCode, definitionId, newConfiguration);

      if (!isMounted) return;

      dispatch({ type: "SET_DEFINITION", payload: res.data });
    } catch (e) {
      console.error("erreur lors de la sauvegarde", e);
    }
    tableRef.current && tableRef.current.recomputeGrids();
  }

  // gestion des selections de rows -----------------------------------------------------------------------------------

  function onRowSelect(index: number | "ALL") {
    dispatch({ type: "SELECT_ROW", payload: index });
    tableRef.current && tableRef.current.recomputeGrids();
    track("ir::row::unselect");
  }

  function onRowUnselect(index: number | "ALL") {
    dispatch({ type: "UNSELECT_ROW", payload: index });
    tableRef.current && tableRef.current.recomputeGrids();
    track("ir::row::unselect");
  }

  function toggleRowSelect(index: number | "ALL") {
    if (index === "ALL") {
      if (interactiveReport.selectedRows.length === interactiveReport.totalRecords) {
        onRowUnselect("ALL");
      } else {
        onRowSelect("ALL");
      }
    } else {
      if (interactiveReport.selectedRows.includes(index)) {
        onRowUnselect(index);
      } else {
        onRowSelect(index);
      }
    }
  }

  // gestion du changement de field dans les pojo ---------------------------------------------------------------------

  function onChangeValue(index: number, name: string, value: any) {
    dispatch({ type: "CHANGE_ENTITIES_FIELD", payload: { index, name, value } });
  }

  // gestion du download ----------------------------------------------------------------------------------------------

  async function exportData(type: "excel" | "pdf") {
    track("ir::export", { type: type });
    try {
      const rsqlFilter = searchTerm(
        interactiveReport.columns,
        interactiveReport.search.targetColumn,
        interactiveReport.search.term,
        interactiveReport.search.filters
      );

      const rsql = mapToRSQL(interactions);
      if (rsqlFilter) {
        rsql.and(rsqlFilter);
      }

      const focus = interactiveReport.focus[interactiveReport.activeFocus];

      const columns = interactiveReport.columns.filter(
        col => focus === undefined || (focus.includes && focus.includes.indexOf(col.column) !== -1)
      );

      await getExportData(
        {
          tableName: interactiveReport.tableName,
          rsqlFilter: rsql,
          columns: columns.map(col => col.column),
          sorts: interactiveReport.sortBy,
          breakRows: interactiveReport.breakRows,
          groupBy: interactiveReport.groupBy,
          aggregates: interactiveReport.aggregates,
          startIndex: 0,
          stopIndex: 6000,
          reset: true
        },
        type,
        columns.map(col => col.label)
      );
      track("ir::export::success");
    } catch (e) {
      track("ir::export::error");
      console.error("erreur lors du téléchargement des données", e);
    }
  }

  // action spécifique admin ------------------------------------------------------------------------------------------

  function resetAfterColumnUpdate() {
    const oldActiveFocus = interactiveReport.activeFocus;
    getInteractiveReportDefinition(definitionId).then(res => {
      dispatch({ type: "ADMIN_RESET_AFTER_COL_CHANGES", payload: res.data });
      dispatch({ type: "CHANGE_FOCUS", payload: oldActiveFocus });
    });
  }

  // on retourne les data de notre hook -------------------------------------------------------------------------------

  return {
    state: interactiveReport,
    interactions,
    computedState: {
      customColumnsSize
    },
    dialog: {
      breakRows: dialogBreakRows,
      groupBy: dialogGroupBy,
      computedColumn: dialogComputedColumn,
      aggregate: dialogAggregate,
      highlights: dialogHighlights,
      save: dialogSave,
      filter: dialogFilter,
      AdminSave: dialogAdminSave,
      AdminCreate: dialogAdminCreate
    },
    dispatch,
    columnsAction: {
      toggleVisibility,
      hideAllColumns,
      showAllColumns,
      onReorderColumn,
      onResizeColumn
    },
    dataAction: { fetchDataInternal, loadMoreData, onChangeValue },
    searchAction: {
      onKeyDownSearchTerm,
      onChangeSearchTerm,
      executeSearch,
      changeTargetColumn,
      onSelectFilter,
      clearFilters,
      setFilters
    },
    breakRowsAction: { validateBreakRows },
    groupByAction: { validateGroupBy },
    computedColumnAction: { validateComputedColumn, deleteComputedColumn, deleteAllComputedColumn },
    aggregatesAction: { validateAggregate, deleteAggregate, deleteAllAggregate },
    highlightsAction: { validateHighlights, deleteHighlights, deleteAllHighlights },
    sortAction: { toggleSort },
    focusAction: {
      onSave,
      onFocusChange,
      onFocusDelete,
      onDelete,
      onAdminUpdate,
      onAdminCreate,
      changeHighlightDelete,
      highlightDelete
    },
    admin: {
      resetAfterColumnUpdate
    },
    selectionsAction: { onRowSelect, onRowUnselect, toggleRowSelect },
    exportData
  };

  // const saveState = useSaveProperties();
}

export function useIsOpen(type: string) {
  const [isOpen, setIsOpen] = useState(false);
  function toggle() {
    setIsOpen(old => {
      track(`${type}::toggle`, { isOpen: !old });
      return !old;
    });
  }

  return { isOpen, toggle, setIsOpen };
}

interface FetchDataParams {
  rsqlFilter: RSQLCriteria | undefined;
  tableName: string;
  columns: string[];
  sorts: SortProperties[];
  startIndex: number;
  stopIndex: number;
  breakRows: string[];
  groupBy: {
    columns: string[];
    functionList: GroupByFunctionParameter[];
  };
  aggregates: AggregateProperties[];
  reset: boolean;
}
async function fetchData(
  {
    startIndex = 0,
    stopIndex = IR_MINIMUM_BATCH_SIZE,
    reset = false,
    tableName,
    rsqlFilter,
    sorts,
    columns,
    breakRows,
    groupBy,
    aggregates
  }: FetchDataParams,
  source?: CancelTokenSource
) {
  let sortsBy = sorts;

  const rsql = initRsqlCriteria();

  for (let rowColumn of breakRows) {
    let direction: "asc" | "desc";
    const sort = sortsBy ? sortsBy.find(col => col.column === rowColumn) : null;
    if (sort != null) {
      direction = sort.sort === "ASC" ? "asc" : "desc";
      // on exclut la colonne que l'on a trouvé
      sortsBy = sortsBy.filter(col => col.column !== rowColumn);
    } else {
      direction = "asc";
    }
    rsql.orderBy.add(rowColumn, direction);
  }

  if (rsqlFilter) {
    rsql.and(rsqlFilter);
  }

  const includes = groupBy.columns.length > 0 ? [...groupBy.columns] : [...columns];

  if (groupBy.functionList && groupBy.functionList.length > 0) {
    for (let groupByFn of groupBy.functionList) {
      includes.push(groupByFn.label);
    }
  } else if (aggregates) {
    for (let aggr of aggregates) {
      includes.push(aggr.id);
    }
  }

  if (sortsBy) {
    for (let sort of sortsBy) {
      if (
        sort.sort &&
        (groupBy.functionList.length === 0 ||
          groupBy.columns.includes(sort.column) ||
          groupBy.functionList.filter(fn => fn.label === sort.column).length > 0)
      ) {
        rsql.orderBy.add(sort.column, sort.sort === "ASC" ? "asc" : "desc");
      }
    }
  }

  const res = await findViewComplex(
    {
      tableName,
      first: startIndex,
      size: stopIndex - startIndex,
      includes: includes,
      filter: rsql.build(),
      groupBy,
      aggregates,
      breakRows
    },
    source
  );

  return {
    start: startIndex,
    size: stopIndex - startIndex,
    entities: res.data.data,
    totalRecords: res.data.meta.totalRecords,
    reset
  };
}

async function getExportData(
  {
    startIndex = 0,
    stopIndex = IR_MINIMUM_BATCH_SIZE,
    tableName,
    rsqlFilter,
    sorts,
    columns,
    breakRows,
    groupBy,
    aggregates
  }: FetchDataParams,
  typeExport: "excel" | "pdf",
  labels: string[]
) {
  try {
    const rsql = initRsqlCriteria();

    for (let rowColumn of breakRows) {
      rsql.orderBy.add(rowColumn, "asc");
    }

    if (sorts) {
      for (let sort of sorts) {
        if (sort.sort) {
          rsql.orderBy.add(sort.column, sort.sort === "ASC" ? "asc" : "desc");
        }
      }
    }

    if (rsqlFilter) {
      rsql.and(rsqlFilter);
    }

    const includes = groupBy.columns.length > 0 ? [...groupBy.columns] : [...columns];

    if (groupBy.functionList && groupBy.functionList.length > 0) {
      for (let groupByFn of groupBy.functionList) {
        includes.push(groupByFn.label);
      }
    } else if (aggregates) {
      for (let aggr of aggregates) {
        includes.push(aggr.id);
      }
    }

    await downloadInteractiveReportExport(
      {
        tableName,
        first: startIndex,
        size: stopIndex - startIndex,
        includes: includes,
        filter: rsql.build(),
        groupBy,
        aggregates,
        breakRows,
        labels
      },
      typeExport
    );
  } catch {
    console.log("error during the download of the excel file");
  }
}
